<template>
  <v-icon
    v-if="mdAndUp"
    class="custom-background position-absolute custom-set-position-top-right"
    style="margin-right: 0">
    mdi-close
  </v-icon>

  <div v-else class="d-flex justify-end align-end">
    <v-icon class="custom-background custom-make-smaller-and-fix-positioning">mdi-close</v-icon>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { mdAndUp } = useDisplay()
</script>

<style scoped lang="scss">
.custom-set-position-top-right {
  top: 20px;
  right: 20px;
}

.custom-background {
  background: rgb(var(--v-theme-surface-gradient));
  padding: 1.6rem;
  border-radius: 50%;
}

.custom-make-smaller-and-fix-positioning {
  transform: scale(0.8) translate(20px, -14px);
}
</style>
